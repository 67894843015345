import { mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";

export default function Downloads() {
    return (
        <>
            <div className="uk-container uk-container-xsmall">
                <div className="uk-section uk-text-center">
                    <p>
                        <img src="media/symbol.png" style={{ height: '75px' }} />
                    </p>

                    <h1>
                        ¡Bienvenido a <strong>Proyección Web</strong>!
                    </h1>
                    <p>
                        Presiona sobre el siguiente enlace para descargar el E-Book gratuito y conoce como es que un sitio web puede ayudarte a potenciar las ventas de tu negocio
                    </p>
                    <p>
                        <a href="/media/docs/proyeccionweb.com%20-%20como%20aumentar%20tus%20ventas%20con%20un%20sitio%20web.pdf" target="_blank" className="uk-button uk-button-primary uk-button-large">
                            <Icon path={mdiDownload} size={1} /> Descargar E-Book
                        </a>
                    </p>
                    <p>
                        <Link to="/" className="uk-button uk-button-link">
                            Volver a la página principal
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
}