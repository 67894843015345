import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "uikit/dist/css/uikit.min.css";
import "uikit/dist/js/uikit";
import "uikit/dist/js/uikit-icons";
import "./../css/app.scss";
import "./../css/uikit-theme.scss";
import Home from "./home";
import PrivacyPolicy from "./privacy-policy";
import Footer from "./shared/footer";
import Header from "./shared/header";
import Downloads from "./downloads";

export default function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Header />

                <main className="uk-flex-1">
                    <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="downloads" Component={Downloads} />
                        <Route path="politica-de-privacidad" Component={PrivacyPolicy} />
                    </Routes>
                </main>

                <Footer />
            </BrowserRouter>
        </React.StrictMode>
    );
}