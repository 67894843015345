import { mdiApi, mdiApplicationCogOutline, mdiApplicationEditOutline, mdiApplicationOutline, mdiBullhornOutline, mdiCart, mdiCheck, mdiCloudOutline, mdiDatabaseOutline, mdiEmail, mdiFacebook, mdiInstagram, mdiLinkedin, mdiMonitorAccount, mdiMonitorCellphone, mdiPhone, mdiSearchWeb, mdiWeb } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import Contact, { iContactProps } from "./shared/contact";
import FloatingButtons from "./shared/floating-buttons";

export default function Home() {
    const [options, setOptions] = useState<iContactProps>({ message: "" });

    useEffect(() => {
        if (options?.message) {
            document.querySelector("#Contact")?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }, [options])

    const handleLanding = () => {
        setOptions({
            message: "Me interesa una landing page para mi negocio ¿podrías darme más información?",
            value: 8000
        });
    }

    const handleWebsite = () => {
        setOptions({
            message: "Me interesa una página web para mi empresa ¿podrías darme más información?",
            value: 12000
        });
    }

    const handleStore = () => {
        setOptions({
            message: "Me interesa una tienda en línea para mi negocio ¿podrías darme más información?",
            value: 15000
        });
    }

    const handleWhatsApp = () => {
        const section = document.querySelector("#Contact");
        section?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        setTimeout(() => {
            try {
                (section?.querySelector("form input") as HTMLInputElement).focus();
            } catch (e) {

            }
        }, 500)
    }

    return (
        <>
            <FloatingButtons onWhatsAppClick={handleWhatsApp} />
            <div
                className="uk-flex uk-flex-middle uk-cover-container"
                data-uk-height-viewport="">

                <video autoPlay loop muted playsInline data-uk-cover="">
                    <source src="/media/video/main-background.mp4" type="video/mp4" />
                </video>

                <div style={{ zIndex: '2' }} className="uk-container uk-container-expand uk-light main-panel">
                    <div className="uk-width-4-5@s uk-width-2-3@m uk-width-1-2@xl uk-padding-large uk-margin-xlarge-top"
                        style={{ backgroundColor: '#00000099' }}>
                        <h1 className="uk-heading-medium">
                            <strong>
                                Creamos soluciones para empresas con tecnología en la nube
                            </strong>
                        </h1>
                        {/*<p className="uk-text-lead">*/}
                        {/*    ¿Necesitas una página web empresarial, una tienda en línea o un sistema personalizado? Estos son solo algunos de los servicios que ofrecemos.*/}
                        {/*</p>*/}
                        <p className="uk-text-lead">
                            Contamos con más de 15 años de experiencia en el diseño, desarrollo y mantenimiento de sistemas de alto rendimiento.
                        </p>
                        {/*<p className="uk-text-lead">*/}
                        {/*    Solicita asesoría gratuita y/o una consultoría que te permitirá encontrar las áreas de oportunidad en tu empresa y las soluciones aplicables que tenemos para ti.*/}
                        {/*</p>*/}
                        <a href="#AboutUs" data-uk-scroll="" className="uk-button uk-button-large uk-button-primary">
                            Saber Más
                        </a>
                    </div>
                </div>
            </div>
            <div className="uk-container">
                <div className="uk-grid-collapse uk-child-width-1-2@s uk-flex-bottom" data-uk-grid="">
                    <div>
                        <section id="AboutUs" className="uk-section uk-section-large">
                            <h2>
                                <strong>
                                    ¿Quiénes Somos?
                                </strong>
                            </h2>
                            <p>
                                Somos una empresa que nace de un grupo de profesionales con una experiencia de más de 15 años desarrollando software de alto rendimiento en la nube.
                            </p>
                            <p>
                                Nuestro compromiso es llevar tu negocio al mundo digital con sitios web que no solo son visualmente atractivos, sino también altamente funcionales y seguros. Nos convertimos en tu aliado tecnológico, apoyándote en cada paso del proceso de desarrollo para garantizar el éxito continuo.
                            </p>
                            <h3>
                                <strong>
                                    Nuestras marcas propias:
                                </strong>
                            </h3>
                            <div className="uk-child-width-auto" data-uk-grid="">
                                <div>
                                    <a href="https://brookers.mx" aria-label="Logo de Brookers" target="_blank">
                                        <img src="media/logo-brookers.png" alt="Logo de Brookers" style={{ height: '40px' }} />
                                    </a>
                                </div>
                                <div>
                                    <a href="https://catalogodeinmuebles.com" aria-label="Logo de Catálogo de Inmuebles" target="_blank">
                                        <img src="media/logo-cdi.png" alt="Logo de Catálogo de Inmuebles" style={{ height: '40px' }} />
                                    </a>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="uk-flex-first@s" data-uk-scrollspy="cls: uk-animation-slide-left; delay: 500">
                        <img src="media/img/about-us.png" alt="Hombre con una laptop en las manos" data-uk-img />
                    </div>
                </div>
            </div>

            <div className="uk-position-relative uk-overflow-hidden">
                <img src="media/img/services.webp"
                    className="uk-position-top-right uk-height-1-1"
                    alt="Escritorio minimalista con una computadora mostrando un diseño tecnológico "
                    style={{ maxWidth: 'initial' }}
                />
                <section id="Services" className="uk-section uk-section-primary uk-position-relative uk-gradient-primary-top-right">
                    <div className="uk-container">
                        <h2>
                            <strong>
                                Servicios Generales
                            </strong>
                        </h2>
                        <div className="uk-child-width-1-2 uk-child-width-1-3@m uk-margin-large-top" data-uk-grid="" data-uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 500">
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiCart} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        Ventas online
                                    </strong>
                                </h3>
                                <p>
                                    Montamos tu tienda en línea con sistemas de fácil administración y te damos las herramientas para que atiendas a tus clientes.
                                </p>
                            </div>
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiApplicationOutline} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        Páginas web
                                    </strong>
                                </h3>
                                <p>
                                    Sitios web visualmente atractivos, funcionales, de fácil administración y que se adaptan a cualquier dispositivo.
                                </p>
                            </div>
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiWeb} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        Redes sociales
                                    </strong>
                                </h3>
                                <p>
                                    Te registramos en las principales redes sociales y te ayudamos a administrarlas de forma eficiente.
                                </p>
                            </div>
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiBullhornOutline} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        Publicidad digital
                                    </strong>
                                </h3>
                                <p>
                                    Creamos campañas publicitarias a través de los principales sitios en internet para obtener beneficios de forma inmediata.
                                </p>
                            </div>
                            {/*<div>*/}
                            {/*    <div className="icon-circle-outline">*/}
                            {/*        <Icon path={mdiCog} size={2} />*/}
                            {/*    </div>*/}
                            {/*    <h3>*/}
                            {/*        <strong>*/}
                            {/*            Configuración de servicios*/}
                            {/*        </strong>*/}
                            {/*    </h3>*/}
                            {/*    <p>*/}
                            {/*        ¿Necesitas un CRM, sistema contable u otro tipo de servicios en internet? Te registramos y configuramos las opciones disponibles para integrarlo con tus plataformas.*/}
                            {/*    </p>*/}
                            {/*</div>*/}
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiSearchWeb} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        SEO y Buscadores
                                    </strong>
                                </h3>
                                <p>
                                    Optimizamos tu sitio e incluimos todo lo necesario para que se muestre en los buscadores.
                                </p>
                            </div>
                            <div>
                                <div className="icon-circle-outline">
                                    <Icon path={mdiMonitorAccount} size={2} />
                                </div>
                                <h3>
                                    <strong>
                                        Consultorías
                                    </strong>
                                </h3>
                                <p>
                                    Nuestra experiencia en el ramo nos permite detectar cuales son los problemas y mejoras tecnológicas aplicables en cada empresa.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="uk-position-relative uk-overflow-hidden uk-cover-container">
                <img src="media/img/specialized.webp"
                    className="uk-position-top-left uk-filter-grayscale uk-opacity-3 uk-height-1-1"
                    alt="Vista aérea de una ciudad iluminada de noche con un difuminado "
                    data-uk-cover="" />
                <section id="SpecializedServices" className="uk-section uk-position-relative uk-gradient-default-bottom">
                    <div className="uk-container">
                        <div>
                            <h2>
                                <strong>
                                    Especialidades
                                </strong>
                            </h2>
                            <div className="uk-child-width-1-2 uk-child-width-1-3@m uk-margin-large-top" data-uk-grid="" data-uk-scrollspy="target: > div; cls: uk-animation-scale-up; delay: 500">
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiApplicationEditOutline} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Desarrollo personalizado de plataformas en la web
                                        </strong>
                                    </h3>
                                    <p>
                                        Contamos con un selecto grupo de Desarrolladores Senior, especialistas en los lenguajes de
                                        servidor más utilizados, lo que nos permite trabajar cualquier tipo de aplicación en la nube.
                                    </p>
                                </div>
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiMonitorCellphone} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Desarrollo de aplicaciones móviles y multiplataforma
                                        </strong>
                                    </h3>
                                    <p>
                                        Olvídate de desarrollar y pagar por aplicaciones para cada sistema operativo. Trabajamos con
                                        tecnología multiplataforma, que nos permite utilizar la misma aplicación en donde se necesite.
                                    </p>
                                </div>
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiApi} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Desarrollo de APIs e integraciones
                                        </strong>
                                    </h3>
                                    <p>
                                        Conectamos tu negocio con otros servicios y hacemos posible que clientes y proveedores interactúen
                                        con los datos de tu negocio, de forma segura.
                                    </p>
                                </div>
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiDatabaseOutline} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Administración de bases de datos
                                        </strong>
                                    </h3>
                                    <p>
                                        Optimización, respaldos y migraciones. Hacemos todo lo que necesitas para mantener tus datos seguros y eficientes.
                                    </p>
                                </div>
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiApplicationCogOutline} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Administración de servidores y sitios web
                                        </strong>
                                    </h3>
                                    <p>
                                        Ofrecemos mantenimiento preventivo además de la administración.
                                    </p>
                                </div>
                                <div>
                                    <div className="icon-circle-outline">
                                        <Icon path={mdiCloudOutline} size={2} />
                                    </div>
                                    <h3>
                                        <strong>
                                            Servicios en la nube
                                        </strong>
                                    </h3>
                                    <p>
                                        Utilizamos servicios como AWS, Azure y Google Cloud en nuestros  desarrollos y contamos con una
                                        amplia experiencia en su administración y optimización de costos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section id="Packages" className="uk-section uk-section-muted">
                <div className="uk-container">
                    <h2 className="uk-text-center">
                        <strong>
                            Paquetes de Servicio
                        </strong>
                    </h2>
                    {/*<p className="uk-text-center">*/}
                    {/*    A continuación te mostramos algunos de los servicios que mas nos solicitan:*/}
                    {/*</p>*/}
                    <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center uk-grid-match uk-margin-small-top" data-uk-grid="">
                        <div data-uk-scrollspy="cls: uk-animation-slide-left; delay: 500">
                            <div className="uk-card uk-card-hover uk-card-default uk-card-body uk-flex uk-flex-column uk-margin-medium-top uk-margin-medium-bottom">
                                <div className="uk-flex-1">
                                    <img src="media/img/icon-landing-page.webp"
                                        className="uk-display-block uk-margin-auto"
                                        alt="Mujer animada sosteniendo un teléfono con un fondo de una grafica "
                                        data-uk-img="" />
                                    <h3 className="uk-card-title uk-text-center">
                                        <strong>
                                            Landing Page
                                        </strong>
                                    </h3>
                                    <p>
                                        Sitio web de 1 sola página optimizado para la venta de productos/servicios.
                                    </p>
                                    <ul className="uk-list uk-list-disc uk-list-primary">
                                        <li>Dominio, hosting y certificado digital</li>
                                        <li>Correos electronicos personalizados</li>
                                        <li>Página principal (landing page) con formulario de contacto</li>
                                        <li>Chat para atención a clientes</li>
                                    </ul>
                                </div>
                                <div className="uk-text-center">
                                    <div className="price-container">
                                        {/*<span className="price-label">*/}
                                        {/*    Precio especial*/}
                                        {/*</span>*/}
                                        <span className="uk-h3">
                                            <strong>
                                                Desde $15,000 MXN
                                            </strong>
                                        </span>
                                    </div>
                                    <br />
                                    <p className="uk-margin-small-top">
                                        <small>
                                            Servicio por 1 año
                                        </small>
                                    </p>
                                    <button className="uk-button uk-button-primary" onClick={handleLanding}>
                                        Pago Único **
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div data-uk-scrollspy="cls: uk-animation-slide-bottom; delay: 500">
                            <div className="uk-card uk-card-hover uk-card-default uk-card-body uk-flex uk-flex-column starred-package">
                                <div className="uk-flex-1">
                                    <img src="media/img/icon-business-website.webp"
                                        className="uk-display-block uk-margin-auto"
                                        alt="Edificio animado "
                                        data-uk-img="" />
                                    <h3 className="uk-card-title uk-text-center">
                                        <strong>
                                            Página Web Empresarial
                                        </strong>
                                    </h3>
                                    <p>
                                        Sitio web con la información de la empresa y los productos/servicios.
                                    </p>
                                    <ul className="uk-list uk-list-disc uk-list-primary">
                                        <li>Dominio, hosting y certificado digital</li>
                                        <li>Correos electronicos personalizados</li>
                                        <li>Página principal</li>
                                        <li>Hasta 3 páginas de contenido</li>
                                        <li>Página de contacto con mapa y formuario</li>
                                        <li>Chat para atención a clientes</li>
                                    </ul>
                                </div>
                                <div className="uk-text-center">
                                    <div className="price-container">
                                        {/*<span className="price-label">*/}
                                        {/*    Precio especial*/}
                                        {/*</span>*/}
                                        <span className="uk-h3">
                                            <strong>
                                                Desde $22,000 MXN
                                            </strong>
                                        </span>
                                    </div>
                                    <br />
                                    <p className="uk-margin-small-top">
                                        <small>
                                            Pago Único **
                                        </small>
                                    </p>
                                    <button className="uk-button uk-button-primary" onClick={handleWebsite}>
                                        Solicitar Servicio
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div data-uk-scrollspy="cls: uk-animation-slide-right; delay: 500">
                            <div className="uk-card uk-card-hover uk-card-default uk-card-body uk-flex uk-flex-column uk-margin-medium-top uk-margin-medium-bottom">
                                <div className="uk-flex-1">
                                    <img src="media/img/icon-store.webp"
                                        className="uk-display-block uk-margin-auto"
                                        alt="Tienda animada"
                                        data-uk-img="" />
                                    <h3 className="uk-card-title uk-text-center">
                                        <strong>
                                            Tienda en Línea
                                        </strong>
                                    </h3>
                                    <p>
                                        Sitio web diseñado para la venta de un catálogo dinámico con pagos en línea.
                                    </p>
                                    <ul className="uk-list uk-list-disc uk-list-primary">
                                        <li>Dominio, hosting y certificado digital</li>
                                        <li>Correos electronicos personalizados</li>
                                        <li>Página principal</li>
                                        <li>Hasta 3 páginas de contenido</li>
                                        <li>Catálogo de productos, carrito de compras y formulario de pago (tienda en línea)</li>
                                        <li>Página de contacto con mapa y formuario</li>
                                        <li>Chat para atención a clientes</li>
                                    </ul>
                                </div>
                                <div className="uk-text-center">
                                    <div className="price-container">
                                        {/*<span className="price-label">*/}
                                        {/*    Precio especial*/}
                                        {/*</span>*/}
                                        <span className="uk-h3">
                                            <strong>
                                                Desde $35,000 MXN
                                            </strong>
                                        </span>
                                    </div>
                                    <br />
                                    <p className="uk-margin-small-top">
                                        <small>
                                            Pago Único **
                                        </small>
                                    </p>
                                    <button className="uk-button uk-button-primary" onClick={handleStore}>
                                        Solicitar Servicio
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className="uk-text-center">
                        <small>
                            * Precios varian de acuerdo a cada proyecto y estan sujetos a cambios sin previo aviso. Precios en pesos mexicanos, no incluyen IVA.
                            <br />
                            ** Pago único por el diseño del sitio web, después solo se siguen pagando proveedores para mantener el sitio en línea.
                        </small>
                    </p>
                </div>
            </section>

            <div className="uk-container">
                <div className="uk-flex-bottom uk-child-width-1-2@s" data-uk-grid="">
                    <div>
                        <section id="WhyUs" className="uk-section uk-section-large">
                            <div>
                                <h2>
                                    <strong>
                                        ¿Por qué nosotros?
                                    </strong>
                                </h2>
                                <ul className="uk-list">
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            +15 años de experiencia.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Incluimos todo lo necesario para tener tu sitio en línea.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Damos soporte técnico, asesoria y asistencia.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Te entregamos todo*, documentado.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Diseño profesional e imágenes de stock.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Utilizamos herramientas que tu mismo puedes usar.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="uk-text-icon">
                                            <Icon path={mdiCheck} size={1} color="forestgreen" />
                                            Solo trabajamos con proveedores de confianza.
                                        </div>
                                    </li>
                                </ul>
                                <span>
                                    <small>* Consulte politicas de entrega en desarrollos a medida</small>
                                </span>
                            </div>
                        </section>
                    </div>
                    <div data-uk-scrollspy="cls: uk-animation-slide-right; delay: 500">
                        <img src="media/img/why-us.png"
                            className="img-why-us"
                            alt="Dos personas hombro a hombro con vestimenta empresarial "
                        />
                    </div>
                </div>
            </div>
            <section id="Contact" className="uk-section uk-section-muted">
                <div className="uk-container">
                    <div className="uk-child-width-1-2@m" data-uk-grid="">
                        <div className="uk-text-center uk-text-left@s">
                            <h2>
                                <strong>
                                    Información de Contacto
                                </strong>
                            </h2>
                            <br />
                            <div className="uk-child-width-auto@s" data-uk-grid="">
                                <div>
                                    <img src="media/symbol.png"
                                        style={{ height: '100px' }}
                                        className="uk-display-block uk-margin-auto"
                                        alt="Logo de ProyeccionWeb"
                                    />
                                </div>
                                <div className="uk-width-expand">
                                    <h3>
                                        Proyección Web
                                    </h3>
                                    <p>
                                        San Alberto Ote. 245. CP 66266.
                                        <br />
                                        San Pedro Garza García. Nuevo León.
                                    </p>
                                    <p>
                                        <a href="tel:+5218241670501" target="_blank" className="uk-button uk-button-text uk-button-large">
                                            <Icon path={mdiPhone} size={1} /> (824) 167 0501
                                        </a>
                                        <br />
                                        <a href="mailto:contacto@proyeccionweb.com" target="_blank" className="uk-button uk-button-text">
                                            <Icon path={mdiEmail} size={.75} /> contacto@proyeccionweb.com
                                        </a>
                                    </p>
                                    <p>
                                        Visitanos en redes sociales:
                                    </p>
                                    <ol className="uk-iconnav uk-flex-center uk-flex-left@s">
                                        <li>
                                            <a href="https://www.facebook.com/proyeccionweb/" target="_blank" title="Facebook">
                                                <Icon path={mdiFacebook} size={1} /> proyeccionweb
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/proyeccionweb/" target="_blank" title="Instagram">
                                                <Icon path={mdiInstagram} size={1} /> proyeccionweb
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/company/proyeccionweb/" target="_blank" title="LinkedIn">
                                                <Icon path={mdiLinkedin} size={1} /> proyeccionweb
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-tile uk-tile-small uk-tile-default">
                                <Contact {...options} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}