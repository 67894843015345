import { mdiWhatsapp } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

export interface iFloatingButtonProps {
    onWhatsAppClick?: () => void;
}

export default function FloatingButtons(props: iFloatingButtonProps) {
    const handleButtonClick = () => {
        if (typeof props?.onWhatsAppClick == "function") {
            props.onWhatsAppClick();
        }
    }

    return (
        <div className="floating-buttons">
            <button className="button-whatsapp" onClick={handleButtonClick}>
                <Icon path={mdiWhatsapp} size={2} />
            </button>
        </div>
    );
}