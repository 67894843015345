import { mdiEmailOutline, mdiFacebook, mdiInstagram, mdiLinkedin, mdiMenu, mdiPhone } from "@mdi/js";
import Icon from "@mdi/react";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
export default function Header() {
    const location = useLocation();
    const simpleHeaderPaths: string[] = ['/politica-de-privacidad', '/downloads'];
    const useSimpleHeader: boolean = simpleHeaderPaths.some((path) => path == location.pathname);

    const css: string[] = ['uk-light'];
    const pathPrepend: string = useSimpleHeader ? '/' : '';

    if (useSimpleHeader) {
        css.push('uk-background-primary');
    } else {
        css.push('uk-position-top');
    }

    return (
        <>
            <header style={{ zIndex: '3' }} className={css.join(' ')}>
                <div className="uk-container uk-container-expand">
                    <div className="uk-flex uk-flex-between uk-flex-middle">
                        <ul className="uk-iconnav">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=61559744406922" target="_blank" title="Facebook">
                                    <Icon path={mdiFacebook} size={1} />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/proyeccionweb/" target="_blank" title="Instagram">
                                    <Icon path={mdiInstagram} size={1} />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/proyeccionweb/" target="_blank" title="LinkedIn">
                                    <Icon path={mdiLinkedin} size={1} />
                                </a>
                            </li>
                        </ul>
                        <ul className="uk-subnav uk-margin-xsmall-vertical" data-uk-margin="">
                            <li>
                                <a href="mailto:contacto@proyeccionweb.com" aria-label="Enviar correo electrónico">
                                    <Icon path={mdiEmailOutline} size={.75} />
                                    <span className="uk-visible@s">
                                        contacto@proyeccionweb.com
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="tel:+5218241670501" target="_blank">
                                    <Icon path={mdiPhone} size={.75} />
                                    824 167 0501
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <hr className="uk-margin-remove" />

                <div style={{ zIndex: '990' }} data-uk-sticky="sel-target: #MainMenu; cls-active: uk-navbar-sticky uk-background-primary;">
                    <div id="MainMenu">
                        <div className="uk-container uk-container-expand">
                            <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-sticky="">
                                <div className="uk-navbar-left">
                                    <Link to="" className="uk-navbar-item">
                                        <img src="media/logo.png" alt="Logo de ProyeccionWeb" style={{ height: '45px' }} className="uk-logo" />
                                    </Link>
                                </div>
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav uk-visible@m">
                                        <li><a href={pathPrepend + "#AboutUs"} data-uk-scroll="">Quiénes Somos</a></li>
                                        <li><a href={pathPrepend + "#Services"} data-uk-scroll="">Servicios</a></li>
                                        {/*<li><a href="#SpecializedServices" data-uk-scroll="">Servicios Especializados</a></li>*/}
                                        <li><a href={pathPrepend + "#Packages"} data-uk-scroll="">Paquetes</a></li>
                                        <li><a href={pathPrepend + "#WhyUs"} data-uk-scroll="">Por qué nosotros</a></li>
                                        <li><a href={pathPrepend + "#Contact"} data-uk-scroll="">Contacto</a></li>
                                    </ul>
                                    <button type="button" data-uk-toggle="target: #responsive-menu" aria-label="Menú" className="uk-navbar-item uk-button uk-button-link uk-hidden@l">
                                        <Icon path={mdiMenu} size={1} />
                                    </button>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            <div id="responsive-menu" className="uk-hidden@l" data-uk-offcanvas="">
                <div className="uk-offcanvas-bar">
                    <button className="uk-offcanvas-close" type="button" title="Cerrar" data-uk-close=""></button>

                    <ul className="uk-nav uk-nav-default" data-uk-nav="">
                        <li><a href={pathPrepend + "#AboutUs"} data-uk-scroll="">Quiénes Somos</a></li>
                        <li><a href={pathPrepend + "#Services"} data-uk-scroll="">Servicios</a></li>
                        <li><a href={pathPrepend + "#Packages"} data-uk-scroll="">Paquetes</a></li>
                        <li><a href={pathPrepend + "#WhyUs"} data-uk-scroll="">Por qué nosotros</a></li>
                        <li><a href={pathPrepend + "#Contact"} data-uk-scroll="">Contacto</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}