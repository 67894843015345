import { mdiWhatsapp } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";

export interface iContactProps {
    message: string;
    value?: number;
}

export default function Contact(props: iContactProps) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [comments, setComments] = useState('');

    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props?.message) {
            setComments(props?.message || '');

            setTimeout(() => {
                input.current?.focus();
            }, 500)
        }
    }, [props])

    const handleName = (e: ChangeEvent) => {
        const input: HTMLInputElement = e.target as HTMLInputElement;
        setName(input?.value || '');
    }

    const handleEmail = (e: ChangeEvent) => {
        const input: HTMLInputElement = e.target as HTMLInputElement;
        setEmail(input?.value || '');
    }

    const handleCompany = (e: ChangeEvent) => {
        const input: HTMLInputElement = e.target as HTMLInputElement;
        setCompany(input?.value || '');
    }

    const handleComments = (e: ChangeEvent) => {
        const input: HTMLTextAreaElement = e.target as HTMLTextAreaElement;
        setComments(input?.value || '');
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        let message = comments.trim();

        if (!message.endsWith(".")) message += ".";

        let text = `¡Hola! mi nombre es ${name} y mi e-mail ${email}`;

        if (company) {
            text += ` de la empresa ${company}`;
        }

        text += `. ${message}`;

        const link = document.createElement("a");
        link.setAttribute("href", "https://wa.me/528241670501?text=" + encodeURIComponent(text));
        link.setAttribute("target", "_blank");
        link.click();

        (window as any).gtag("event", "generate_lead", {
            currency: "MXN",
            value: props?.value || 0
        });

        (window as any).fbq('track', 'Lead', {
            currency: "MXN",
            value: props?.value || 0
        });
    }

    return (
        <form action="" method="post" className="uk-form-stacked" onSubmit={handleSubmit}>
            <h4>
                <strong>
                    Habla con uno de nuestros representantes
                </strong>
                <br />
                <small>
                Envianos tu mensaje y recibe atención inmediata y personalizada
                </small>
            </h4>
            <div className="uk-grid-small uk-child-width-1-2" data-uk-grid="">
                <div>
                    <label htmlFor="name" className="uk-form-label">
                        Tu Nombre:
                    </label>
                    <input type="text" id="name" name="name" autoComplete="given-name" className="uk-input" value={name} onChange={handleName} required ref={input} />
                </div>
                <div>
                    <label htmlFor="email" className="uk-form-label">
                        Tu E-Mail:
                    </label>
                    <input type="email" id="email" name="email" autoComplete="email" className="uk-input" value={email} onChange={handleEmail} required />
                </div>
                <div className="uk-width-1-1">
                    <label htmlFor="company" className="uk-form-label">
                        Empresa:
                    </label>
                    <input type="text" id="company" name="company" autoComplete="organization" className="uk-input" value={company} onChange={handleCompany} />
                </div>
                <div className="uk-width-1-1">
                    <label htmlFor="comments" className="uk-form-label">
                        Comentarios:
                    </label>
                    <textarea id="comments" name="comments" className="uk-textarea" value={comments} onChange={handleComments} required></textarea>
                </div>
            </div>
            <div className="uk-margin-top">
                <button type="submit" className="uk-button uk-button-primary uk-width-1-1 uk-width-auto@s">
                    <Icon path={mdiWhatsapp} size={.75} style={{ marginTop: '-3px' }} /> Continuar en WhatsApp
                </button>
            </div>
        </form>
    );
}