import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="uk-section uk-section-small uk-section-secondary">
            <div className="uk-container">
                <div className="uk-flex-between uk-child-width-auto@s uk-text-center uk-text-left@s uk-grid-small" data-uk-grid="">
                    <div>
                        &copy; {(new Date()).getFullYear()} Proyeccion Web. Todos los derechos reservados.
                    </div>
                    <div>
                        <Link to="politica-de-privacidad" className="uk-button uk-button-text">
                            Políticas de privacidad
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}