import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    return (
        <>
            {/* <header className="uk-background-primary"> */}
            {/*     <div className="uk-container uk-container-expand"> */}
            {/*         <nav className="uk-navbar uk-navbar-container uk-navbar-transparent"> */}
            {/*             <div className="uk-navbar-left"> */}
            {/*                 <Link to="/" className="uk-navbar-item"> */}
            {/*                     <img src="media/logo.png" alt="Logo de ProyeccionWeb" style={{ height: '45px' }} className="uk-logo" /> */}
            {/*                 </Link> */}
            {/*             </div> */}
            {/*             <div className="uk-navbar-right"> */}
            {/*                  */}
            {/*             </div> */}
            {/*         </nav> */}
            {/*     </div> */}
            {/* </header> */}
            <div className="uk-section">
                <div className="uk-container">
                    <header>
                        <h1>Aviso de privacidad</h1>
                    </header>
                    <main>
                        <p>
                            Proyección Web (en adelante "PROYECCIÓN WEB") es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
                        </p>
                        <section>
                            <p>
                                <strong>
                                    ¿Para qué fines utilizaremos sus datos personales?
                                </strong>
                            </p>
                            <p>
                                Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
                            </p>
                            <ul className="uk-list">
                                <li>
                                    Conocer sus necesidades tecnológicas y enviarle propuestas basadas en las mismas.
                                </li>
                                <li>
                                    Conocer su opinión, quejas, agradecimientos o cualquier otro comentario. relacionado con el sitio web o nuestros servicios.
                                </li>
                                <li>
                                    Contactar con usted en caso de ser necesario.
                                </li>
                            </ul>
                            <p>
                                En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, desde este momento usted nos puede comunicar lo anterior,
                                enviándonos un correo a nuestra cuenta contacto@stecnologicas.com con la siguiente información:
                            </p>
                            <ul>
                                <li>
                                    Nombre del titular de los datos personales.
                                </li>
                                <li>
                                    Domicilio o cualquier otro medio de contacto.
                                </li>
                                <li>
                                    Documentos que acrediten su personalidad e identidad.
                                </li>
                                <li>
                                    La descripción clara y precisa de los datos personales.
                                </li>
                                <li>
                                    Cualquier otro documento que facilite la localización de los datos.
                                </li>
                            </ul>
                        </section>
                        <section>
                            <p>
                                <strong>
                                    ¿Qué datos personales utilizaremos para estos fines?
                                </strong>
                            </p>
                            <p>
                                Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
                                Nombre, Apellidos e Información de Contacto (dirección, correo electrónico, teléfono) entre otros.
                            </p>
                        </section>
                        <section>
                            <p>
                                <strong>
                                    ¿Cómo puede revocar su consentimiento para el uso de sus datos personales?
                                </strong>
                            </p>
                            <p>
                                Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.
                                Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata,
                                ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines,
                                la revocación de su consentimiento implica que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
                            </p>
                        </section>
                        <section>
                            <p>
                                <strong>
                                    El uso de tecnologías de rastreo en nuestro portal de Internet
                                </strong>
                            </p>
                            <p>
                                Le informamos que en nuestra página de Internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su
                                comportamiento como usuario de Internet, así como brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página.
                            </p>
                            <p>
                                Con la mayoría de los exploradores para Internet, usted puede borrar las Cookies del disco rígido de su computadora, bloquear todas las Cookies o recibir un mensaje de alerta
                                antes de que se almacene una Cookie. Por favor, consulta las instrucciones de su explorador para conocer más sobre estas funciones.
                            </p>
                        </section>
                        <section>
                            <p>
                                <strong>
                                    ¿Cómo puede conocer los cambios a este aviso de privacidad?
                                </strong>
                            </p>
                            <p>
                                El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales;
                                de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
                            </p>
                            <p>
                                Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad a través del correo electrónico o nuestro sitio web.
                            </p>
                        </section>
                    </main>
                </div>
            </div>
        </>
    );
}